@import 'styles/imports';

.footer {
    // position: relative;
    // bottom: 0;
    background-color: $main-dark;
    width: 100%;
    color: $grey-light;
    margin-top: 10vh;
    justify-content: space-between;
    align-items: center;
    font-size: $font-small-medium;
    height: 6em;
    margin: 0;
    
}

.company_name {
    height: 100%;
    width: 45%;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
    margin-right: min(6vw, 50px);
}

.contact_us {
    height: 100%;;
    width: 45%;
    align-items: center;
    justify-content: flex-start;

    &__text {
        margin: 0 1em 0 0;
        color: $grey-light;
        width: fit-content;
    }

    &__linkedin {
        width: 1.5em;
        filter: $grey-light-filter;
        cursor: pointer;
        margin: 0 1em;
    
        &:hover {
            filter: $blue-light-filter;
        }
    }

    &__mail {
        padding-top: 0.4em;
        height: 1.6em;
        color: $grey-light;

        &:hover {
            filter: $blue-light-filter;
        }
    }
}

.build_info {
    // width: 10em;
    // margin-left: -10em;
    // font-size: 15px;
    // margin-right: 5px;
    // color: $grey;
    user-select: none;
    margin-right: 0.5em;
}


// .contact {
    
// }
