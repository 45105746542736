$font-smallest: min(0.5vh, 0.5vw, 12px);
$font-smaller: min(1vh, 0.75vw, 14px);
$font-small: min(1.5vh, 1vw, 16px);
$font-small-medium: max(min(1.75vh, 1.1vw, 17px), 11px);
$font-medium: max(min(2vh, 1.25vw, 18px), 12px);
$font-large: max(min(2.5vh, 1.5vw, 20px), 14px);
$font-larger: max(min(3vh, 2vw, 24px), 16px);
$font-largest: max(min(3.5vh, 2.5vw, 28px), 20px);

$font-xl:  max(min(4vh, 3vw, 32px), 24px);
$font-2xl: max(min(5vh, 3.5vw, 36px), 28px);
$font-3xl: max(min(6vh, 4vw, 40px), 32px);
$font-4xl: max(min(7vh, 5vw, 48px), 38px);
