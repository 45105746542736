@import 'styles/imports';

.component {
    margin-top: 70px;
    margin-bottom: 70px;
    color: $grey-light;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: transparent;
}

.article {
    z-index: 100;
    flex-direction: column;
    width: 80%;
    max-width: 1000px;
}

.titleI {
    font-family: 'Lalezar', cursive;
    font-size: $font-3xl;
    margin-top: 40px;
}

.titleII {
    font-family: 'Lalezar', cursive;
    font-size: $font-xl;
    margin-top: 80px;
}

.para {
    font-size: $font-medium;
    display: block;
    line-height: 1.6em;
    margin: 5px 0;
}




.solutions {
    grid-template-columns: 50% 50%;
    width: 80%;
    max-width: 1000px;
    display: grid;
    justify-content: space-around;
    gap: 50px;
    z-index: 100;
    justify-items: center;
    justify-content: center;
    margin-top: 50px;
    
    &__card {

        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        width: 85%;
        background-color: rgba(16, 33, 56, 0.8);
        color: #E8E8E8;
        padding: 7%;

        &:hover {box-shadow: 2px 8px 15px $main-dark; transition: box-shadow .2s ease-in;}
    
    
    
        &__img {width:100%; height: 300px; object-fit: cover; overflow: hidden;}
        &__header {
            width: 100%; 
            text-align: left; 
            font-size: $font-largest;
            font-weight: 500;
            font-family: 'Lalezar', cursive; 
            margin: 15px 0px 5px 0px;
        }
        &__text {
            width: 100%; 
            text-align: left; 
            font-size: $font-medium; 
            margin: 5px 0px;
            
        }
    
    
    
        &__button {
            font-size: $font-medium;
            background-color: $blue;
            height: fit-content;
            width: fit-content;
            padding: 10px 25px;
            // margin: 15px 0px 0px 0px;
            margin-top: auto !important;
            border-radius: 3px;
            justify-content: center;
            align-items: center;
            &:hover {background-color: $blue-light; cursor: pointer;}
        }
    }
}


@media screen and (max-width: 764px) {

    .titleI {
        font-family: 'Lalezar', cursive;
        font-size: $font-xl;
        margin-top: 40px;
    }
    
    .titleII {
        font-family: 'Lalezar', cursive;
        font-size: $font-3xl;
        margin-top: 80px;
    }

    .para {
        font-size: $font-large;
        display: block;
        line-height: 1.6em;
        //line-height: max(min(2.5vh, 1.75vw, 30px), 17px);
        margin: 5px;
    }

    .solutions {
        grid-template-columns: 80%;
        width: 90%;
        max-width: 1000px;
        display: grid;
        justify-content: space-around;
        gap: 50px;
        z-index: 100;
        justify-items: center;
        justify-content: center;
        
        &__card {
    
            flex-direction: column;
            align-items: center;
            border-radius: 5px;
            width: 85%;
            height: fit-content;
            background-color: rgba(16, 33, 56, 0.8);
            color: #E8E8E8;
            padding: 7%;
        
        
        
            &__img {width:100%; height: 300px; object-fit: cover; overflow: hidden;}

            &__header {
                width: 100%; 
                text-align: left; 
                font-size: $font-xl; 
                font-family: 'Lalezar', cursive;
                font-weight: 500;
                margin: 15px 0px 5px 0px;
            }
            
            &__text {
                width: 100%; 
                text-align: left; 
                font-size: $font-medium; 
                margin: 5px  0 20px 0px;
                line-height: 1.6em;
            }
        
        
        
            &__button {
                font-size: $font-medium;
                background-color: $blue;
                height: fit-content;
                width: fit-content;
                padding: 10px 25px;
                margin: 0px;
                border-radius: 3px;
                justify-content: center;
                align-items: center;
                &:hover {background-color: $blue-light; cursor: pointer;}
            }
        }
    }
}
