@import 'styles/imports';

.button {
    //width: 12em;
    height: 2.5em;
    margin: 0.5em;

    font-size: 1.2rem;
    border-radius: 5px;
    color: $grey-light;

    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
}

.spinner {
    color: $grey-light;
    font-size: 1.25rem;
    align-items: center;

    & > .ant-spin {
        color: $grey-light;
    }
}

.icon {
    justify-content: flex-start;
    margin-right: 0.5em;
    align-items: center;
    //font-size: 1.35em;
    height: 100%;
    //width: 2em;
    padding: 0 0 0 0.5em;
    object-fit: contain;
}

.text {
    width: 100%;
    //margin: 0 1em;
    //padding-bottom: 0.25em;
    margin: 0;
    padding: 10% 0;
    
    //align-items: center;
    //justify-content: center;
    user-select: none;
    text-decoration: none;

    &__with_icon {justify-content: flex-start;}
    &__no_icon {justify-content: center;}
}

.blue {
    background-color: $blue;
    &:hover {background-color: $blue-light;}
}

.red {
    background-color: $red;
    &:hover {background-color: $red-light;}
}

.disabled {
    background-color: grey;
    cursor: default;
}

.green {
    background-color: $green;
    &:hover {background-color: $green-light;}
}