@import './imports';

.ant-form {
  width: 80%;
  color: $grey-light !important;
}

.ant-form-item {
  width: 100%;
}

.ant-row {
  width: 100%;
}

.ant-col.ant-form-item-label {
  width: 30%;
  justify-content: right;
}

.ant-col.ant-form-item-control {
  width: 50% !important;
}

.ant-col.ant-form-item-label label {
  color: $grey-light !important;
}

.ant-form-item-margin-offset {
  display: none;
}

.ant-checkbox-wrapper  {
  color: $grey-light !important;
}

.ant-input {
  background-color: $main;
  border: 1px solid #e8e8e880;
  color: $grey-light;
}

.ant-input-filled {
  background-color: $main !important;
  border: 1px solid #e8e8e880 !important;
  color: $grey-light !important;
  -webkit-text-fill-color: $grey-light;
}

.ant-input-filled:focus {
  background-color: $main;
  border: 1px solid #e8e8e8 !important;
  color: $grey-light;
}

.ant-input-filled:hover {
  background-color: $main-light !important;
  border: 1px solid #e8e8e880 !important;
  color: $grey-light !important;
}

.ant-input-filled:-webkit-autofill,
.ant-input-filled:-webkit-autofill:hover,
.ant-input-filled:-webkit-autofill:focus,
.ant-input-filled:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
  transition: color 9999s ease-out, background-color 9999s ease-out;
  transition-delay: 9999s;
}

.ant-select-selection-search-input:-webkit-autofill,
.ant-select-selection-search-input:-webkit-autofill:hover,
.ant-select-selection-search-input:-webkit-autofill:focus,
.ant-select-selection-search-input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
  transition: color 9999s ease-out, background-color 9999s ease-out;
  transition-delay: 9999s;
}

.ant-select-dropdown {
  background-color: $main;
  padding: 0;
  border-radius: 0px;
}

.ant-select-item {
  background-color: $main-dark;
  color: $grey-light;
  border-radius: 0px !important;
}

.ant-select-item-option-content {
  color: $grey-light;
}

.ant-select-selector {
  background-color: $main !important;
  color: #e8e8e8 !important;
  border: 1px solid #e8e8e880 !important;
}

.ant-select-selector:hover {
  background-color: $main-light !important;
  color: #e8e8e8 !important;
  border: 1px solid #e8e8e880 !important;
}

.ant-select-item-option-selected {
  background-color: $main-light !important;
}

.ant-select-arrow {
  color: #e8e8e8 !important;
}

.ant-select-selection-item {
  color: #e8e8e8 !important;
}

.ant-form-item-control-input-content:has(> .ant-btn) {
  justify-content: center;
}


.ant-btn-primary {
  width: fit-content;
  height: fit-content;
  justify-self: center;
}

.ant-btn-primary > span {
  padding: 0px 5px;
}

.ant-progress-text:not(:has(> .anticon)) {
  color: $grey-light !important;
  font-size: 20px !important;
}



@media only screen and (max-width: 930px) {
  .ant-col.ant-form-item-label {
    width: 42%;
    justify-content: right;
  }

}

@media only screen and (max-width: 575px) {
  .ant-col.ant-form-item-label {
    width: 42%;
    justify-content: left;
    padding: 0px;
  }
  
  .ant-col.ant-form-item-label:has(> .ant-form-item-no-colon) {
    display: none;
  }  

}
