$main: #183153;
$main-light: #204270;
$main-dark: #102138;

$blue: #409eff;
$blue-light: #66B2FF;
$blue-dark: #207edf;

$grey-light: #E8E8E8;
$grey: #AAAAAA;
$grey-dark: #555555;

$green: #4da53f;
$green-light: #72ac56;

$red-dark: #ff3838;
$red: #f56c6c;
$red-light: #f78989;

// Filters

$main-filter: invert(14%) sepia(66%) saturate(757%) hue-rotate(179deg) brightness(95%) contrast(93%);
$main-light-filter: invert(19%) sepia(19%) saturate(4137%) hue-rotate(190deg) brightness(93%) contrast(86%);
$main-dark-filter: invert(12%) sepia(70%) saturate(499%) hue-rotate(175deg) brightness(87%) contrast(100%);

$blue-filter: invert(48%) sepia(91%) saturate(1149%) hue-rotate(189deg) brightness(101%) contrast(101%);
$blue-light-filter: invert(75%) sepia(36%) saturate(6323%) hue-rotate(188deg) brightness(107%) contrast(101%);

$grey-light-filter: invert(98%) sepia(2%) saturate(584%) hue-rotate(70deg) brightness(111%) contrast(82%);
$grey-filter: invert(69%) sepia(2%) saturate(0%) hue-rotate(224deg) brightness(98%) contrast(92%);
$grey-dark-filter: invert(35%) sepia(0%) saturate(1513%) hue-rotate(184deg) brightness(88%) contrast(88%);

$green-filter: invert(53%) sepia(95%) saturate(308%) hue-rotate(65deg) brightness(86%) contrast(93%);
$green-light-filter: invert(59%) sepia(24%) saturate(812%) hue-rotate(56deg) brightness(100%) contrast(86%);

$red-dark-filter: invert(32%) sepia(92%) saturate(1610%) hue-rotate(337deg) brightness(103%) contrast(119%);
$red-filter: invert(58%) sepia(94%) saturate(3285%) hue-rotate(325deg) brightness(115%) contrast(92%); // Loss 3.6
$red-light-filter: invert(84%) sepia(50%) saturate(4737%) hue-rotate(309deg) brightness(108%) contrast(94%); // Loss 4.2