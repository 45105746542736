@import 'styles/imports';

.component {
    flex-direction: column;
    background-color: rgba($main-dark, 0.6);
    //padding: 1em 2em;
    font-size: $font-larger;
}

.name_wrap {
    flex-direction: row;
    //margin: -1em 0 -1em -2em;
    background-color: $main-light;
    //width: calc(100% + 4em);
    height: 2.5em;
    align-items: center;
    padding-left: 0.5em;
    cursor: pointer;
    width: auto;

    &:hover {background-color: $blue-dark;}
}

.name {
    // margin-bottom: 1em;
    padding-left: 0.5em;
    align-items: center;
    &__tri {
        transition: all 250ms;
        filter: $grey-light-filter; 
        width: 0.75em; 
        height: 1em;
        transform: rotate(90deg);
    }
}

.deg180 { transform: rotate(180deg); }

.fields {
    font-size: $font-large;
    flex-direction: column;
    margin-top: 1em;
    padding: 0 2.5em 2em 2.5em;
    // animation: show_vac 250ms ease-in-out;
    overflow: hidden;
    width: auto;
    line-height: 1.6em;
}

.field {
    flex-direction: column;

    &__subheader {
        margin: 0.5em 0 0.5em 0; 
        font-size: $font-large; 
        font-weight: 700;
    }

    &__list {margin: 0;}
    &__description {margin: 0; }
}

// @keyframes show_vac {
//     from {height: 0;}
//     to {height: 100%;}
// }

@media screen and (max-width: 764px) {

    .fields {
        font-size: $font-large;
        flex-direction: column;
        margin-top: 1em;
        padding: 0 1.5em 2em 1.5em;
        overflow: hidden;
        width: auto;
    }

    .field {
    
        &__list {padding-left: 2em;}
        &__description {margin: 0; }
    }
}