@import 'styles/imports';

.component {
    margin-top: 70px;
    margin-bottom: 70px;
    color: $grey-light;
    justify-content: center;
    background: transparent;
}

// .bg {  
//     background: linear-gradient(138.73deg, #183153 0%, rgba(24, 49, 83, 0) 85.69%), linear-gradient(170.31deg, #183153 27.96%, rgba(24, 49, 83, 0) 100%);
//     width: 100%;
//     height: calc(100vh - 70px);
//     position: fixed;
//     bottom: 0;
//     z-index: 1;
// }

// .vanBg {
//     width: 100%;
//     height: calc(100vh - 70px);
//     object-fit: cover;
//     position: fixed;
//     bottom: 0;
// }

.article {
    z-index: 100;
    flex-direction: column;
    width: 80%;
    max-width: 1000px;
}

.titleI {
    font-family: 'Lalezar', cursive;
    font-size: $font-3xl;
    margin-top: 40px;
    margin-bottom: 40px;
}

.titleII {
    font-family: 'Lalezar', cursive;
    font-size: $font-xl;
    margin-top: 80px;
}

.para {
    font-size: $font-large;
    display: block;
    line-height: 40px;
    margin: 2% 10%;
}

.paraBottom {
    font-size: $font-large;
    display: block;
    line-height: 40px;
    padding: 0px 20px;
}


.content {
    flex-direction: column;
    gap: 70px;
}

.img {
    width: 40%;
    object-fit: cover;
}

.redir {
    text-decoration: underline;
    color: $grey-light;
    cursor: pointer;

    &:hover {
        color: $blue-light;
    }
}




@media screen and (max-width: 764px) {

    .titleI {
        font-family: 'Lalezar', cursive;
        font-size: $font-4xl;
        margin-top: 40px;
    }
    
    .titleII {
        font-family: 'Lalezar', cursive;
        font-size: $font-3xl;
        margin-top: 80px;
    }

    
    .para {
        font-size: $font-large;
        display: block;
        line-height: 28px;
        margin: 0px;
    }

    .paraBottom {
        font-size: $font-large;
        display: block;
        line-height: 28px;
    }
    
    .contentSec {
        flex-direction: column;
    }

    .content {
        margin: 40px 0px 0px 0px;
        gap: 40px;
    }
    .img {
        width: 100%;
        height: 160px;
    }

}


