@import 'styles/imports';

.veil {
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.3);
    inset: 0;
    z-index: 10;
}

.dialogue_window {
    background-color: $main;
    color: $grey-light;
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%,-50%);

    border: 1px solid $blue;

    align-items: flex-start;
    flex-direction: column;
    width: auto;
}

.content {
    width: 100%;
}

.resizable {
    min-width: 40vw;
    min-height: 30vh;
    max-width: 95vw;
    max-height: 90vh;
    overflow-y: hidden;
    overflow-x: auto;
    resize: both;
}

.hide {
    transition: 0.01s;
    transform: translateX(-9999px);    
}

.header {
    background-color: $main-light;
    width: 100%;
    justify-content: center;
    height: 2.5em;
    font-size: $font-medium;
    flex-direction: row;
    display: flex;
    
    &__label {
        display: flex;
        margin: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        user-select: none;
        margin-left: 1.25em;
        color: $grey-light;
    }

    &__close {
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: $font-largest;
        
        height: 100%;
        width: auto;
        aspect-ratio: 1/1;
        cursor: pointer;

        &:hover {
            background-color: $main-dark;
        }
    }
}

.help {
    height: 100%;
    justify-content: center;
    font-size: $font-medium;
    margin-left: -15%;
    width: 5em;
    align-items: center;
    cursor: pointer;

    &:hover {background-color: $main-dark;}
    
    &__button { font-size: $font-large; margin-left: 0.5em;}

    &__tips {
        min-width: 250px;
        font-size: $font-medium;
        flex-direction: column;
        color: $grey-light;
        position: absolute;
        text-align: left;
        left: 101%;
        top: 0;
        background-color: rgba(#000000, $alpha: 0.8);
        padding: 1em;
        border-radius: 10px;
        animation: show_hint 0.25s;
    }

    &__tip {
        flex-direction: column;
        
        &__text {
            white-space: pre-wrap;
        }
        &__link {
            text-decoration: underline;
            cursor: pointer;
            &:hover { color: $blue-light;}
        }
    }
}

@keyframes show_hint {
    from {opacity: 0;}
    to {opacity: 1;}
}