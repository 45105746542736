@import 'styles/imports';

.dividerI {
    background: radial-gradient(50% 50% at 50% 50%, #18315380 0%, rgba(24, 49, 83, 0) 100%);
    width: 100%;
    height: 3px;
}

.dividerII {
    background: #18315380;
    width: 100%;
    height: 1px;
}

.navbar {
    position: sticky;
    top: 0;
    //height: 3em;
    font-size: $font-large;
    flex-direction: row;
    // background-color: $main;
    color: $grey-light;
    width: 100%;
    align-items: center;
    position: fixed;
    height: 70px;
    transition: opacity 1s ease-in-out;
    transition-delay: 15s;
    z-index: 10000;
    opacity: 0;

    &.fadein {
        opacity: 1;
        position: sticky;
        top: 0;
        //height: 3em;
        font-size: $font-large;
        flex-direction: row;
        // background-color: $main;
        color: $grey-light;
        width: 100%;
        align-items: center;
        position: fixed;
        height: 70px;
        transition: opacity 1s ease-in-out;
        z-index: 10000;
        transition-delay: 15s;
    }
}


.home {
    // background-color: $main;
    flex-direction: row;
    color: $grey-light;
    width: 100%;
    align-items: center;
    // border-bottom: 2px solid $main;
    background-color: transparent;
    transition-duration: 0.5s;
}

.page {
    background-color: $main-light;
    flex-direction: row;
    color: $grey-light;
    width: 100%;
    align-items: center;
    border-bottom: 2px solid $main-dark;
    transition-duration: 0.5s;
}

.logo {
    height: 70px;
    margin-left: 1vw;
    object-fit: contain;
    cursor: pointer;
    transition: opacity 1s ease;
}


.links {
    margin: 0px 40px;
    gap: 15px;
}

.link {
    justify-content: center;
    cursor: pointer;
    transition: 0.1s ease-in;
    margin: 0px;
    padding: 16px 0px;
    font-size: $font-large;

    &:hover {
        color: $blue-light;
    }

    &__selected {
        justify-content: center;
        color: $blue-light;
        margin: 0px;
        padding: 16px 0px;
        font-size: $font-large;
        cursor: pointer;
    }
}


.corner {
    justify-content: flex-end;
    margin: 0px 25px;
    height: 25px;
    font-size: $font-large;
    //color: #e8e8e89d;        
    cursor: pointer;
    // width: fit-content;
    // &:hover {
    //     color: $blue-light;
    // }
}

.button {
    width: fit-content;
    padding: 7px 30px;
    background-color: #409EFF;
    border-radius: 5px;
    font-size: $font-medium;
    height: fit-content;
    align-self: center;
}

.corner__mobile {
    display: none;
}

.sidebar {
    display: none;
}

@media screen and (max-width: 764px) {
    .links {
        display: none;
    }

    .corner {
        display: none;
    }

    .corner__mobile {
        display: flex;
        justify-content: flex-end;
        margin: 0px 25px;
        height: 25px;
    }

    .burger {
        display: flex;
        flex-direction: column;
        width: 35px;
        height: 25px;
        justify-content: space-between;
    }

    .cancel {
        display: block;
        width: 35px;
        height: 25px;
        position: absolute;
    }

    .line {
        width: 35px;
        height: 3px;
        border-radius: 1px;
        background-color: $grey-light;

    }

    .line__cancel1 {
        width: 35px;
        height: 3px;
        border-radius: 1px;
        background-color: $grey-light;
        transform: rotate(45deg);
        transition: 0.1s ease-in;
        position: relative;
        top: 13px;
        left: 0;
        bottom: 0;
        right: 0;
    }

    
    .line__cancel3 {
        width: 35px;
        height: 3px;
        border-radius: 1px;
        background-color: $grey-light;
        transform: rotate(-45deg);
        transition: 0.1s ease-in;
        position: relative;
        top: 10px;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .sidebar {
        display: flex;
        max-width: 250px;
        width: 75%;
        height: 100vh;
        position: absolute;
        background-color: white;
        top: 0;
        bottom: 0;
        left: -250px;
        flex-direction: column;

        &__open {
            transition: 0.5s;
            left: 0;
        }
        
    }

    .links__sidebar {
        margin-top: 30px;
        color: black;
        display: flex;
        flex-direction: column;
    }

    .name {
        font-size: 20px;
        //color: #18315380;
        justify-content: center;
        margin-top: 30px;
    }

    .button {
        color: $grey-light;
    }

}
