@import 'styles/imports';

div, p, nav, section, article, main, footer {
    display: flex;
    width: 100%;
}

.app {
    width: 100%;
    min-height: 100vh;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    

}

.pageWrapper {
    width: 100vw;
    height: 100vh;
}

.bg {  
    background: 
        linear-gradient(138.73deg, #183153 0%, rgba(24, 49, 83, 0) 85.69%), 
        linear-gradient(170.31deg, #183153 27.96%, rgba(24, 49, 83, 0) 100%);
    width: 100%;
    height: calc(100vh - 70px);
    position: fixed;
    bottom: 0;
    z-index: -10;
}

.bgblack {
    width: 100%;
    height: calc(100vh - 70px);
    position: fixed;
    bottom: 0;
    z-index: -10;
    background: rgba(0, 0, 0, 0.60);
}

.not_found {
    width: 80%;
    margin-top: 100px;
    white-space: pre-wrap;
    color: $grey-light;
    margin-left: 5vw;
    font-size: $font-largest;
    flex-direction: column;
}

.vanBg {
    width: 100%;
    height: calc(100vh - 70px);
    object-fit: cover;
    position: fixed;
    bottom: 0;
    z-index: -20;
}

.introVideo {
    // width: 100%;
    // height: 100%;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    z-index: 10000000;
    transition: opacity 1s ease;
}

.introVideo.fade-out {
    opacity: 0;
}



@media only screen and (max-width: 764px) {
  .video {
    overflow: hidden;
  }
  
    .vanBg {
        height: 100vh;
        position: fixed;

    }
}
