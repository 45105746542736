@import 'styles/imports';

.component {
    margin-top: 70px;
    margin-bottom: 70px;
    color: $grey-light;
    justify-content: center;
    background: transparent;
}

// .bg {  
//     background: linear-gradient(138.73deg, #183153 0%, rgba(24, 49, 83, 0) 85.69%), linear-gradient(170.31deg, #183153 27.96%, rgba(24, 49, 83, 0) 100%);
//     width: 100%;
//     height: calc(100vh - 70px);
//     position: fixed;
//     bottom: 0;
//     z-index: 1;
// }

// .vanBg {
//     width: 100%;
//     height: calc(100vh - 70px);
//     object-fit: cover;
//     position: fixed;
//     bottom: 0;
// }

.article {
    z-index: 100;
    flex-direction: column;
    width: 80%;
    max-width: 1000px;
}

.titleI {
    font-family: 'Lalezar', cursive;
    font-size: $font-3xl;
    margin-top: 40px;
}

.titleII {
    font-family: 'Lalezar', cursive;
    font-size: $font-xl;
    margin-top: 80px;
}

.para {
    font-size: $font-medium;
    display: block;
    line-height: max(min(2.5vh, 1.75vw, 30px), 17px);
    margin: 5px;
}

.figure {
    width: 100%;
    max-width: 700px;
    object-fit: contain;
    align-self: center;
}

.acc {
    font-family: 'Lalezar', cursive;
    font-size: $font-larger;
}

.logo {
    width: 30%;
    height: 67px;
    align-self: center;
    fill: $grey-light;
    object-fit: contain;
}

.figure__avac {
    width: 100%;
    max-width: 700px;
    object-fit: contain;
    align-self: center;
    position: relative;
    border-radius: 10px;
}

.figure__avacSpec {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


.forwho {
    flex-direction: column;
    gap: 50px;
}

// .team {
//     flex-direction: row;
//     margin-top: 3vh;
// }

// .teamLoc {
//     flex-direction: column;
//     align-items: center;
// }

// .teamLocImg {
//     object-fit: cover;
//     width: 90%;
//     height: 170px;
// }

// .teamLocAlt {
//     justify-content: center;
//     margin: 5px;
//     font-size: $font-small-medium;
// }

.li {
    display: block;
    float: left;
    width: 12px;
    height: 12px;
    margin-right: 20px;
    border-radius: 100%;
    border: 2px solid $blue;
    
    box-shadow: inset 0px 0px 10px 2px #439efa80,
                        0px 0px 10px 2px #40a0ff49;
    animation: pulse 1s alternate infinite;
    position: absolute;
  }

@keyframes pulse {
    0% {
        background: rgba(255,255,255,0.3);
        box-shadow: inset 0px 0px 10px 2px #66b3ff80,
                        0px 0px 5px 2px #40a0ff38;
    }
    100% {
        background: rgba(255,255,255,1);
        box-shadow: inset 0px 0px 10px 2px #479aec80,
                        0px 0px 15px 2px #3a97f3;
    }
}

@media screen and (max-width: 764px) {
    .team {
        flex-direction: column;
    }
    
    .teamLocAlt {
        justify-content: center;
        margin: 5px 5px 20px 5px;
        font-size: $font-medium;
    }
    .acc {
        font-family: 'Lalezar', cursive;
        font-size: $font-larger;
    }

    .titleI {
        font-family: 'Lalezar', cursive;
        font-size: $font-xl;
        margin-top: 40px;
    }
    
    .titleII {
        font-family: 'Lalezar', cursive;
        font-size: $font-xl;
        margin-top: 80px;
    }

    .para {
        font-size: $font-large;
        display: block;
        line-height: 1.6em;
        margin: 5px;
    }

    
    .logo {
        width: 20%;
        height: 67px;
        align-self: center;
        fill: $grey-light;
        object-fit: contain;
    }
}

