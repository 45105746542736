@import 'styles/imports';

.component {
    flex-direction: column;
    background-color: $main;
    color: $grey-light;
    align-items: center;
    width: auto;
    min-width: 500px;
    max-width: 800px;
    // max-width: (min(50vw, 700px));
    padding: 0.5vw 2vw 0.5vw 1vw;
}

.icon_text_wrapper {
    width: 100%;
    //max-width: 50vw;
    justify-content: center;
    align-items: center;

    //margin: 0.5em 0;
}

.icon {
    font-size: $font-4xl;
    margin-left: 0.5em;
    justify-content: center;
    align-items: center;
    
    &__error {color: $red-dark;}
    &__success {color: $green;}
    &__warning {color: yellow;}
}

.description_and_message {
    flex-direction: column;
    width: 100%;
    font-size: $font-larger;
    margin: 0.5em 1em;
    
}

.description {
    margin: 0 0 0.5em 0;
    white-space: pre-wrap;
    margin-bottom: 2em;
}

.message {
    margin: 0 0 0 0;
    text-align: left;
    word-wrap: break-word;
    white-space: pre-wrap;
    line-height: 1.75em;
    //word-break: break-all;
}

.ok {
    width: 6em;
    height: 2em;
    margin: 0.5em;

    font-size: $font-large;
    border-radius: 5px;
    color: $grey-light;

    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    background-color: $blue;
    &:hover {background-color: $blue-light;}
}

@keyframes fade_away {
    from {opacity: 1;}
    to {opacity: 0;}
  }