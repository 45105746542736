@import 'styles/imports';


.slideup {
  opacity: 0.5;
  transform: translateY(100px);
  transition: all 0.7s ease;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.home {
  width: 100%;
  height: fit-content;
  flex-direction: column;
  // overflow-y: hidden;
}

.section_one {
  height: 100vh;
  width: 100%;
  justify-content: center;
  min-height: 600px;

  
  &__text_wrap {
    width: 100%;
    height: 100%;
    flex-direction: column;
    color: $grey-light;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 2000px;
    min-height: 600px;


    &__header {
        font-size: $font-2xl;
        margin-top: 0;
        width: 60%;
    }
    &__desc {
        font-size: $font-large;
        line-height: 1.6em;
        width: 60%;
    }
    &__button {
      background-color: $blue;
      width: fit-content;
      margin: 10px;
      padding: 6px 20px;
      border-radius: 6px;
      transition: all 0.2s ease-in;
      cursor: pointer;

      &:hover {
        background-color: $blue-light;
      }
    }

    &__scrolldown {
      position: absolute;
      left: 50%;
      bottom: 35px;
      // color: #e8e8e8;
      width: 50px;
      height: 50px;
      display: block;
      text-align: center;
      font-size: 20px;
      text-decoration: none;
      text-shadow: 0;
      width: 13px;
      height: 13px;
      border-bottom: 2px solid $grey-light;
      border-right: 2px solid $grey-light;
      -webkit-transform: translate(-50%, 0%) rotate(45deg);
      -moz-transform: translate(-50%, 0%) rotate(45deg);
      transform: translate(-50%, 0%) rotate(45deg);
      -webkit-animation: fade_move_down 4s ease-in-out infinite;
      -moz-animation: fade_move_down 4s ease-in-out infinite;
      animation: fade_move_down 4s ease-in-out infinite;

      cursor: pointer;

    }
  }

}

@-webkit-keyframes fade_move_down {
  0% {
    -webkit-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% { opacity: 1; }
  100% {
    -webkit-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
@-moz-keyframes fade_move_down {
  0% {
    -webkit-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% { opacity: 1; }
  100% {
    -webkit-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
@keyframes fade_move_down {
  0% {
    -webkit-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% { opacity: 1; }
  100% {
    -webkit-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}

.video {
  width: calc(100% + 7px);
  height: 100%;
  object-fit: cover;
  overflow-x: hidden;
}

.section_two {
  height: 95vh;
  min-height: 800px;
  width: 100%;
  background-color: $main;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__contentWrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 2000px;
  }

  &__title {
    width: fit-content;
    color: $grey-light;
    font-size: $font-xl;
  }

  &__desc {
    width: 70%;
    color: #e8e8e8b9;
    font-size: $font-large;
    margin-top: 20px;
  }

  &__menu {
    flex-direction: row;
    margin-top: 50px;
    width: 75%;
    min-width: 800px;
    height: 500px;
    background-color: $main;
    transition: all 1s ease-in-out;
    z-index: 1000;
  }
  
  &__demo {
    width: 55%;
    height: 100px;
  }

  &__info {
    flex-direction: column;
    width: 45%;
    gap: 2px;

    &__item {
      flex-direction: column;

      &__header {
        align-items: center;
        text-align: center;
        color: $grey-light;
        font-size: $font-medium;
        background-size: 300% 100%;

        moz-transition: all 1s ease-in-out;
        -o-transition: all 1s ease-in-out;
        -webkit-transition: all 1s ease-in-out;
        transition: all 1s ease-in-out;
        cursor: pointer;

        background-image: linear-gradient(to right, $main-dark, $main-light, $main-dark, #2b5876, #4e4376);
        box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);

        &:hover {
          background-position: 100% 0;
          moz-transition: all 1s ease-in-out;
          -o-transition: all 1s ease-in-out;
          -webkit-transition: all 1s ease-in-out;
          transition: all 1s ease-in-out;

          .leftBar {
            transform: rotate(45deg);
            left: 3.5px;
          }
          .rightBar {
            transform: rotate(-45deg);
          }
        }
        &:focus {
          outline: none;
        }

      }

      &__title {
        justify-content: space-around;
      }

      &__desc {
        width: calc(100% - 60px);
        font-size: $font-small-medium;
        color: #e8e8e8bb;
        padding: 50px 30px;
        max-height: 1000px;
        transition: all 0.1s ease-in;

        &__hidden {
          max-height: 0;
          padding: 0 30px;
          overflow: hidden;
          transition: all 0.1s ease;
        }
      }

    }
  }
}



// .top {
//   //width: 70vw;
//   min-width: 675px;
//   height: calc(100vh - 70px);
//   justify-content: space-between;
//   // margin-top: 25vh;
//   align-items: center;
//   display: flex;
//   flex-direction: row;
  
// }

$iconWidth: 20px;
$iconHeight: 20px;
$left: 2px; //(iconWidth / 2) - ((iconWidth / 2) / sec(35deg))


.arrowIcon {
  width: $iconWidth;
  height: $iconHeight;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

.leftBar {
  position: absolute;
  top: calc(($iconHeight / 2) - 2px);
  left: $left;
  width: calc($iconWidth / 2);
  height: 2px;
  background-color: $grey-light;
  transform: rotate(35deg);
  transition: all 0.5s cubic-bezier(.25,1.7,.35,.8);
}

.rightBar {
  position: absolute;
  top: calc(($iconHeight / 2) - 2px);
  left: calc($iconWidth / 2);
  width: calc($iconWidth / 2);
  height: 2px;
  background-color: $grey-light;
  transform: rotate(-35deg);
  transition: all 0.5s cubic-bezier(.25,1.7,.35,.8);
}

.open {
  .leftBar {
    transform-origin: center center;
    transform: rotate(0deg);
  }
  .rightBar {
    transform-origin: center center;
    transform: rotate(0deg);
  }

}





// $bgwidth: calc(((100vw * 0.75) * 0.55) * 1.2);
// $bgheight: calc((646 / 1034) * $bgwidth);

// $bgheight: min(50vh, 600px);
// $bgwidth: calc((1034 / 646) * $bgheight);

// $bgwidth: 100%;
// $bgheight: calc((646 / 1034) * $bgwidth);

$bgwidth: min(50vw, 800px);
$bgheight: calc((646 / 1034) * $bgwidth);

.modeldata {
  // height: 50v
  height: $bgheight;
  width: 100%;
  position: relative;
  z-index: -1;
  transition: all 1s ease-in;

  &:hover {
  
    .modeldata__labeledtracklet {
      left: calc((($bgwidth / 1034) * 333) - 100px);
      transform-origin: left center;
      transform: perspective(1000px) rotateY(-20deg);
    }
    .modeldata__labeledresult {
      position: absolute;
      left: calc((($bgwidth / 1034) * 333) + 70px);
      transform-origin: left center !important;
      transform: perspective(1000px) rotateY(-55deg) !important;

    }

    .modeldata__nolabeledresult {
      position: absolute;
      left: calc((($bgwidth / 1034) * 333) + 100px);
      transform-origin: left center !important;
      transform: perspective(1000px) rotateY(-35deg) !important;

    }
    
  }

  &__bg {
    width: $bgwidth;
    height: $bgheight;
    object-fit: cover;
    transition: opacity 1s ease-in;
  }

  &__labeledtracklet {
    position: absolute;
    object-fit: cover;
    width: calc(($bgwidth / 1034) * 288);
    left: calc(($bgwidth / 1034) * 333);
    top: calc(($bgheight / 646) * 201);
    transition: all 0.3s ease-in;
    // display: none;
    z-index: 3;
  }

  &__labeledresult {
    // display: none;
    position: absolute;
    object-fit: cover;
    width: calc((($bgwidth / 1034) * 288) - 50px);
    left: calc(($bgwidth / 1034) * 333);
    top: calc(($bgheight / 646) * 201);
    z-index: 2;
    transition: all 0.3s ease-in;
    opacity: 90%;


    // &:hover {
    //   left: calc((($bgwidth / 1034) * 333) + 50px);
    //   transform-origin: left center !important;
    //   transform: perspective(1000px) rotateY(-35deg) !important;
    // }
  }

  &__nolabeledresult {
    // display: none;
    position: absolute;
    object-fit: cover;
    width: calc((($bgwidth / 1034) * 288) - 50px);
    left: calc(($bgwidth / 1034) * 333);
    top: calc(($bgheight / 646) * 201);
    transition: all 0.3s ease-in;
    // &:hover {
    //   left: calc((($bgwidth / 1034) * 333) + 100px);
    //   transform-origin: left center !important;
    //   transform: perspective(1000px) rotateY(-35deg) !important;
    // }
  }
}

.device {
  // height: 40vh;
  height: calc($bgwidth / 2);
  width: $bgwidth;
  position: relative;
  z-index: -1;

  &__bg {
    width: 100%;
    object-fit: contain;
    transition: opacity 1s ease-in;
  }

}

.cloud {
  // height: 40vh;
  height: calc($bgwidth / 2);
  width: $bgwidth;
  position: relative;
  z-index: -1;

  &__bg {
    width: 100%;
    object-fit: contain;
    transition: opacity 1s ease-in;
  }

}


.privacy {
  // height: 40vh;
  height: calc($bgwidth / 2);
  width: $bgwidth;
  position: relative;
  z-index: -1;

  &__bg {    
    width: 90%;
    object-fit: cover;
    transition: opacity 1s ease-in;
    border-radius: 20px;
    box-shadow: 5px 5px 10px #000000c9;
  }

}





@keyframes slideUP {
  from {
    transform: translateY(100%);
    -ms-transform: translateY(100%);
  }
  to {
    transform: translateY(0);
    -ms-transform: translateY(0);
  }
}



.section_three {
  height: 65vh;
  width: 100%;
  min-height: 600px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // transform: translateY(100%);
  animation: slideUp 0.5s ease forwards;
  -ms-animation: slideUp 0.5s ease forwards;
  
  &__title {
    width: fit-content;
    color: $grey-light;
    font-size: $font-xl;
  }

  &__partnerslogos {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 7%;
    opacity: 90%;

    &__vmaccel {
        object-fit: contain;
        width: 15%;
    }

    &__amd {
        object-fit: contain;
        width: 12%;
    }

    &__tencent {
        object-fit: contain;
        width: 5%;
    }

    &__excelpoint {
        object-fit: contain;
        width: 17%;
    }

  }

  &__desc {
    width: 65%;
    flex-direction: column;

    &__text {
      margin-top: 50px;
      font-size: $font-large;
      line-height: 1.6em;
      color: #e8e8e8f1;
    }

    &__link {
      text-decoration: underline;
      color: $grey-light;
      cursor: pointer;
      margin-top: 20px;

      &:hover {
        color: $blue-light;
      }
    }
  }
}

.section_four {
  height: 95vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $main;
  min-width: 600px;
  min-height: 800px;

  &__title {
    width: fit-content;
    color: $grey-light;
    font-size: $font-xl;
    margin-top: 10vh;
  }

  &__cards {
    flex-direction: row;
    justify-content: space-between;
    width: 70vw;
    margin: 50px 0;
    gap: 3%;
  }

  &__card {
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      width: 20vw;
      height: 30vw;
      background-color: rgba($main-dark, 0.8);
      color: $grey-light;
      padding: 20px;

      &:hover {box-shadow: 2px 8px 15px $main-dark; transition: box-shadow .2s ease-in;}

      &__img {width: 100%; height: 66%; object-fit: cover; overflow: hidden;}
      &__header {width: 100%; text-align: left; font-size: $font-large;}
      &__text {
          width: 100%; 
          text-align: left; 
          font-size: $font-medium;
      }

      &__button {
          height: 2.5em;
          width: 10em; 
          border-radius: 3px;
          font-size: $font-medium; 
          background-color: $blue;
          justify-content: center;
          align-items: center;
          padding: 5px 3px;
          &:hover {background-color: $blue-light; cursor: pointer;}
      }
  }
  
}

.section_five {
  background: 
      linear-gradient(138.73deg, #183153 0%, rgba(24, 49, 83, 0) 85.69%), 
      linear-gradient(170.31deg, #183153 27.96%, rgba(24, 49, 83, 0) 100%);
  
  height: 80vh;
  min-height: 770px;
}


.form {
  color: $grey-light;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__title {
      font-size: $font-2xl;
      margin: 30px 0px;
  }

  &__desc {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: #e8e8e8b6;
  }
}

.response {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: radial-gradient($main 15%, transparent 70%);
  // background-color: radial-gradient(circle, $main, transparent);

  & > * {
      width: fit-content;
      margin: 15px;
      display: block;
      text-align: center;
  }

  
  & > p {
      width: fit-content;
      margin: 5px;
  }
}

.urlTag {
  text-decoration: underline;
  color: $grey-light;
  cursor: pointer;

  &:hover {
      color: $blue;
  }
}




@media only screen and (max-width: 800px) {
  .section_one {
    height: 100vh;
    width: 100%;

  
    
    &__text_wrap {
      width: 100%;
      height: 100%;
      flex-direction: column;
      color: $grey-light;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
  
      &__header {
          font-size: 28px;
          width: 95%;
      }
      &__desc {
          font-size: 17px;
          width: 95%;
      }
    }
  }

  .section_two {
    height: fit-content;
    width: 100%;
    padding: 50px 0px;
    align-items: center;
    justify-content: center;    
    overflow-x: hidden;
  
    &__title {
      width: fit-content;
      color: $grey-light;
      font-size: 25px;
      width: 90%;
    }
  
    &__desc {
      width: 90%;
      color: #e8e8e8b9;
      font-size: 15px;
      margin-bottom: 20px;
    }
  
    &__menu {
      flex-direction: column;
      margin-top: 5px;
      min-width: 300px;
      width: 90%;
      height: fit-content;
      flex-flow: column-reverse;
    }
    
    &__demo {
      width: 100%;
      height: fit-content;
      align-items: center;
      justify-content: center;
    }
  
    &__info {
      flex-direction: column;
      width: 100%;
      gap: 2px;
  
      &__item {
        flex-direction: column;
  
        &__title {
          font-size: 17px;
          justify-content: space-around;
        }
  
        &__desc {
          width: calc(100% - 60px);
          font-size: 15px;
          color: #e8e8e8bb;
          padding: 50px 30px;
          max-height: 1000px;
          transition: all 0.1s ease-in;
  
          &__hidden {
            max-height: 0;
            padding: 0 30px;
            overflow: hidden;
            transition: all 0.1s ease;
          }
        }
      }
    }
  }
  
  $bgwidth: 150%;
  $bgheight: calc((646 / 1034) * $bgwidth);

  .modeldata {
    height: fit-content;
    position: relative;
    z-index: -1;
    transition: all 1s ease-in;
    justify-content: center;

    &__bg {
      width: $bgwidth;
      height: $bgheight;
      object-fit: cover;
      transition: opacity 1s ease-in;
    }

    &__labeledtracklet {
      position: absolute;
      object-fit: cover;
      width: calc(($bgwidth / 1034) * 288);
      left: calc((($bgwidth / 1034) * 333) - 25%);
      top: calc(($bgheight / 646) * 201);
      transition: all 0.3s ease-in;
      // display: none;
      z-index: 3;
    }

    &__labeledresult {
      // display: none;
      position: absolute;
      object-fit: cover;
      width: calc((($bgwidth / 1034) * 288) - 50px);
      left: calc((($bgwidth / 1034) * 333) - 25%);
      top: calc(($bgheight / 646) * 201);
      z-index: 2;
      transition: all 0.3s ease-in;
      opacity: 90%;


      // &:hover {
      //   left: calc((($bgwidth / 1034) * 333) + 50px);
      //   transform-origin: left center !important;
      //   transform: perspective(1000px) rotateY(-35deg) !important;
      // }
    }

    &__nolabeledresult {
      // display: none;
      position: absolute;
      object-fit: cover;
      width: calc((($bgwidth / 1034) * 288) - 50px);
      left: calc((($bgwidth / 1034) * 333) - 25%);
      top: calc(($bgheight / 646) * 201);
      transition: all 0.3s ease-in;
      // &:hover {
      //   left: calc((($bgwidth / 1034) * 333) + 100px);
      //   transform-origin: left center !important;
      //   transform: perspective(1000px) rotateY(-35deg) !important;
      // }
    }
  }

  .device {
    height: calc(90vw * 0.7);
    width: 100%;
    position: relative;
    z-index: -1;
  
    &__bg {
      width: 100%;
      object-fit: contain;
      transition: opacity 1s ease-in;
    }
  
  }
  
  .cloud {
    height: calc(90vw * 0.7);
    width: 100%;
    position: relative;
    z-index: -1;
  
    &__bg {
      width: 100%;
      object-fit: contain;
      transition: opacity 1s ease-in;
    }
  
  }
  
  
  .privacy {
    height: calc(90vw * 0.7);
    width: 100%;
    position: relative;
    z-index: -1;
  
    &__bg {    
      width: 90%;
      object-fit: cover;
      transition: opacity 1s ease-in;
      border-radius: 20px;
      box-shadow: 5px 5px 10px #000000c9;
    }
  
  }

  .section_three {
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    height: fit-content;
    
    &__title {
      font-size: 25px;
      width: 90%;
      align-items: center;
      justify-content: center;
    }
  
    &__desc {
      width: 80%;

      &__text {
        font-size: 17px;
      }
    }

    &__partnerslogos {
      width: 70%;
      // flex-direction: row;
      display: grid;
      grid-template-areas: "a b" "c d";
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      gap: 10px;
      opacity: 90%;
  
      &__vmaccel {
          object-fit: contain;
          width: 90%;
          justify-self: self-end;
      }
  
      &__amd {
          object-fit: contain;
          width: 75%;
      }
  
      &__tencent {
          object-fit: contain;
          width: 40%;
          justify-self: center;
      }
  
      &__excelpoint {
          object-fit: contain;
          width: 90%;
      }
  
    }
  }

  .section_four {
    
    height: fit-content;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $main;
    min-width: 300px;

    &__title {
      font-size: 25px;
      width: 90%;
      align-items: center;
      justify-content: center;
    }

    &__cards {
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      gap: 0;
    }

    &__card {
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        width: 250px;
        height: 300px;
        background-color: rgba($main-dark, 0.8);
        color: $grey-light;
        padding: 20px;
        margin: 20px;
    }
  }

  .section_five {
    height: fit-content;
  }
  
  .form {
    justify-content: center;
    align-items: center;
    text-align: center;
      
    &__title {
      width: fit-content;
      color: $grey-light;
      font-size: 25px;
      width: 90%;
      margin: 10px 0px;
    }
  
    &__desc {
      width: 90%;
      color: #e8e8e8b9;
      font-size: 15px;
      margin-bottom: 20px;
    }
  }

}


a {
  text-decoration: none;
  color: $grey-light;
}
