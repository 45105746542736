@import 'styles/imports';

$maxW: min(100vw, 1333px);

.component {
    margin-top: 70px;
    width: 75vw;
    max-width: 1000px;
    color: $grey-light;
    justify-content: center;
    background: transparent;
    flex-direction: column;
    //padding-bottom: 10vh;
    margin-bottom: 70px;
}

.titleI {
    font-family: 'Lalezar', cursive;
    font-size: $font-3xl;
    margin-top: 40px;
}
.para {
    font-size: $font-medium;
    display: block;
    line-height: max(min(2.5vh, 1.75vw, 30px), 17px);
    margin: 5px;
}
.team {
    flex-direction: row;
    margin-top: 3vh;
}

.teamLoc {
    flex-direction: column;
    align-items: center;
}

.teamLocImg {
    object-fit: cover;
    width: 90%;
    height: 170px;
}

.teamLocAlt {
    justify-content: center;
    margin: 5px;
    font-size: $font-small-medium;
}

.culture_header {
    //margin-top: 7.5vh;
    margin-top: 40px;
    flex-direction: column;


    &__bold {
        font-family: 'Lalezar', cursive;
        font-size: $font-3xl;
        margin: 0 0.5em 0 0;
        color: $blue-light;
        width: auto;

        &_right {
            color: $grey-light;
        }
    }

}

.culture_main_text {
    font-size: $font-medium;
    line-height: 1.6em;
}
.images {
    flex-direction: column;

    &__main_row {
        flex-direction: row; 
        justify-content: space-between; 
        margin: 2.5vh 0;
    }
    
    &__col_20 {
        flex-direction: column; 
        width: calc(0.2 * $maxW);
        gap: calc(0.025 * $maxW);
    }

    &__col_30 {
        flex-direction: column; 
        width: calc(0.3 * $maxW);
        gap: calc(0.025 * $maxW);
    }

    &__img_h25 {
        object-fit: cover; 
        height: calc(0.25 * $maxW);
        object-position: 45% 65%;
    }

    &__img_h30 {
        object-fit: cover; 
        height: calc(0.3 * $maxW);
    }

    &__img7 {
        object-fit: cover; 
        width: 100%; 
        height:  calc(0.3 * $maxW); 
        object-position: 45% 65%;
    }
}

.career_header {
    margin-top: 7.5vh;
    flex-direction: column;

    &__bold {
        font-family: 'Lalezar', cursive;
        font-size: $font-2xl;
        margin: 0;
        color: $blue-light;
    }

    &__text {
        font-size: $font-large;
    }
}

.vacancies {
    flex-direction: column;
    gap: 3vh;
}

@media screen and (max-width: 764px) {

    
    .team {
        flex-direction: column;
    }
    
    .teamLocAlt {
        justify-content: center;
        margin: 5px 5px 20px 5px;
        font-size: $font-medium;
    }
    .acc {
        font-family: 'Lalezar', cursive;
        font-size: $font-larger;
    }

    .titleI {
        font-family: 'Lalezar', cursive;
        font-size: $font-xl;
        margin-top: 40px;
    }

    .para {
        font-size: $font-large;
        display: block;
        line-height: 1.6em;
        margin: 5px;
    }


    .culture_header {
        //margin-top: 7.5vh;
        flex-direction: column;
        
        &__bold {
            font-family: 'Lalezar', cursive;
            font-size: $font-xl;
            margin: 0 0.5em 0 0;
            color: $blue-light;
            width: auto;
    
            &_right {
                color: $grey-light;
            }
        }

    }

    .culture_main_text {
        font-size: $font-large;
        line-height: 1.6em;
    }

    .images {

        &__main_row {
            flex-direction: column; 
            justify-content: center; 
            width: 100%; 
            margin: 5vh 0;
            gap: 5vh;
        }
        &__col_20, &__col_30 {width: 100%; justify-content: center; align-items: center; gap: 5vh}

        &__img_h25, &__img_h30 {height: auto; width: 100%;}
    }

}

